import React from 'react';
import './Form.css';

const Form = () => (
	<div className="FormWrapper">
	<div className="Form">
		<form action="https://formspree.io/contact@momtheclown.com" method="POST">
		<input id="yourName" type="text" name="name" placeholder="Your Name"/>
		<input id="yourEmail" type="email" name="_replyto" placeholder="Your Email"/>
		<input id="subject" type="text" name="_subject" value="Question About Booking" />
		<input id="body" type="textarea" name="message" placeholder="Let us answer your questions!"/>
		<input type="hidden" name="_next" value="#" />
		<input type="submit" value="Send"/>
		</form>
	</div>
	</div>
)

export default Form