import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import './Banner.css';

class Banner extends Component {
	render() {
		return (
			<div className="Banner">
				<Navbar>
					<Navbar.Header>
						<Navbar.Brand>
							<Link to="/">Mom the Clown</Link>
						</Navbar.Brand>
					</Navbar.Header>
				</Navbar>
			</div>
		)
	}
}

export default Banner;