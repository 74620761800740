import React, { Component } from 'react';
import './Image.css';

class Image extends Component {
	render() {
		return (
			<div className="Image">
				<img className="Image" src={this.props.file} alt="No alts given"/>
			</div>
		)
	}
}

export default Image;