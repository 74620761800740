import React, { Component } from 'react';
import './App.css';

import Banner from './Banner';
import About from './About';
import Form from './Form';
import SlideShow from './SlideShow';
import { BrowserRouter } from 'react-router-dom';

//import ReactContactForm from 'react-mail-form';
import { Parallax } from 'react-parallax';
import Balloons from './images/background.jpg';

class App extends Component {
  render() {
    return (
      <div className="App">
		<BrowserRouter>
			<Banner />
		</BrowserRouter>
		<Parallax
			blur={5}
            bgImage={Balloons}
            bgImageAlt="the cat"
            strength={750}
        >
			<About />
			<SlideShow />
			<Form />
        </Parallax>
      </div>
    );
  }
}

export default App;
