import React from 'react';
import { Slide } from 'react-slideshow-image';
import "./SlideShow.css";
import Image from './Image';

import Image1 from './images/1.JPG';
import Image2 from './images/2.JPG';
import Image3 from './images/3.JPG';
import Image4 from './images/4.JPG';

const properties = {
  duration: 7000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true
}

const SlideShow = () => {
    return (
	<div className="SlideShowWrapper">
	<div className="SlideShow">
      <Slide {...properties}>
		<Image file={Image1}/>
		<Image file={Image2}/>
		<Image file={Image3}/>
		<Image file={Image4}/>
      </Slide>
	</div>
	</div>
    )
}

export default SlideShow;