import React, { Component } from 'react';
import './About.css';

class About extends Component {
	render() {
		return (
		<div className="AboutWrapper">
			<div className="About">
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus non turpis diam. Ut metus neque, lobortis quis metus sed, pellentesque fringilla metus. Vivamus ante erat, pulvinar vitae tristique eget, iaculis pretium tellus. In ultricies risus at lorem ornare convallis. Nam id tristique justo. Aliquam at ipsum lacus. Mauris elementum, purus ut scelerisque pellentesque, diam turpis luctus magna, non volutpat turpis nunc eget risus. Cras vel libero vel purus ultricies fringilla. In cursus lacus ut massa imperdiet blandit. Donec eget ligula elementum, semper turpis a, vehicula eros. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec tellus velit, sagittis non euismod ut, varius sit amet magna. Integer erat orci, porta eu urna vel, cursus sollicitudin est. Phasellus vestibulum arcu ac nisi tincidunt interdum.</p>

				<p>Curabitur pretium porttitor mauris nec gravida. Curabitur vulputate venenatis tristique. Integer consectetur arcu sem, non tempor erat posuere quis. Praesent et tincidunt purus, vel convallis mi. Fusce mi nisi, venenatis vel magna at, blandit fermentum justo. Nunc justo nisi, vestibulum quis massa sit amet, sollicitudin posuere eros. Phasellus laoreet turpis non eros vestibulum, non tristique justo imperdiet. Aenean sagittis fringilla nisl eget varius. Aenean augue orci, mollis sed magna nec, cursus molestie massa. Praesent scelerisque libero quis placerat sollicitudin. Aliquam pharetra turpis vitae nisl sodales, at tincidunt nisl ullamcorper. Nullam fringilla vitae tortor ut congue. Sed finibus massa dolor, ac finibus urna tempor id. Vestibulum et metus congue, fringilla nulla at, tristique elit. Suspendisse potenti.</p>
			</div>
		</div>
		)
	}
}

export default About;